import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { GatsbyImage } from "gatsby-plugin-image"
// React Bootstrap
import { Container, Col, Row, Card } from "react-bootstrap"

const SecondPage = ({data}) => (
  <Layout>
    <Seo title="Web Design & Development" />
    <Container>
    <h1 className="my-4">Our Web Design / Development Portfolio</h1>
    <Row className="g-4">
    {data.allMarkdownRemark.edges.map(({ node }) => (
            <Col lg={4} xs={12} key={node.id}>
              <Link to={node.fields.slug} className="card-link">
                <Card className="p-1 p-sm-3 pb-0">
                <GatsbyImage 
              image={node.frontmatter.Image01.childImageSharp.gatsbyImageData}
              className="card-img-top"
              alt={node.frontmatter.title}
            />
                  <Card.Body>
                    <Card.Title>{node.frontmatter.title}</Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            ))}
    </Row>
    
    </Container>
  </Layout>
)

export default SecondPage

export const query = graphql`
  query {
    allMarkdownRemark(sort: {fields: frontmatter___title, order: ASC}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            Image01 {
              childImageSharp {
                gatsbyImageData(
                  jpgOptions: {progressive: false, quality: 50}
                  webpOptions: {quality: 40}
                  width: 600
                  placeholder: BLURRED
                )
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`